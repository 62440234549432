import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { concatMap, tap } from "rxjs/operators";
import { SecurityApiService } from "../http/security-api.services";
import { AppUser, AuthenticatedUser, ResourceEnum, RightsEnum, UserGroup, UserGroupEnum } from "./models";

@Injectable({ providedIn: 'root' })
export class AuthorizationService {

    private groupSubject: BehaviorSubject<UserGroup[]> = new BehaviorSubject<UserGroup[]>(null);
    groups$ = this.groupSubject.asObservable();

    private _userHomeSubject: BehaviorSubject<string> = new BehaviorSubject<string>("/");
    userHome$ = this._userHomeSubject.asObservable();

    private _sysAdmin: boolean = false;
    private userGroupList: UserGroup[];
    private resourceRights: Map<ResourceEnum, RightsEnum> = new Map<ResourceEnum, RightsEnum>();

    constructor(
        private securityApiService: SecurityApiService) {
    }

    setupUser(user: AuthenticatedUser): string {
        let home = "/";
        if (user) {
            this._sysAdmin = user.appUser.userGroups.some(g => g.userGroupId == UserGroupEnum.SysAdmin);
            const groups = this.userGroupList.filter(g => this._sysAdmin || user.appUser.userGroups.some(ug => ug.userGroupId === g.userGroupId));
            this.resourceRights.clear();
            groups.filter(g => g.userGroupId != UserGroupEnum.SysAdmin).forEach(g => {
                g.resources.forEach(r => {
                    const resource = r.resource.resourceId;
                    if (!this.resourceRights.has(resource)) {
                        this.resourceRights.set(resource, r.rightsMask);
                    } else {
                        const current = this.resourceRights.get(resource);
                        this.resourceRights.set(resource, current | r.rightsMask);
                    }
                })
            });

            if (this.hasRightsToResource(ResourceEnum.Admin)) {
                home = "/admin";
            } else if (this.hasRightsToResource(ResourceEnum.ClientAdmin)) {
                home = "/client";
            } else if (this.hasRightsToResource(ResourceEnum.Participant)) {
                home = "/participant";
            } else if (this.hasRightsToResource(ResourceEnum.Rater)) {
                home = "/rater";
            }

            this.groupSubject.next(groups);

        } else {
            this._sysAdmin = false;
            this.resourceRights.clear();
            this.groupSubject.next(null);
        }

        this._userHomeSubject.next(home);
        return home;
    }

    initialize(): Observable<UserGroup[]> {
        if (!this.userGroupList) {
            return this.securityApiService.getUserGroups()
                .pipe(
                    tap(result => {
                        this.userGroupList = result;
                    })
                );
        } else {
            return of(this.userGroupList);
        }
    }

    hasRightsToResource(resource: ResourceEnum, rights: RightsEnum = RightsEnum.Read): boolean {
        return this._sysAdmin || (this.resourceRights.get(resource) & rights) > 0;
    }

    rightsToResource(resource: ResourceEnum): RightsEnum {
        return this._sysAdmin ? RightsEnum.All : (this.resourceRights.has(resource)) ? this.resourceRights.get(resource) : RightsEnum.None;
    }

}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { NotificationService } from '../services';

@Injectable()
export class ExceptionInterceptor implements HttpInterceptor {
    constructor(
        private notificationService: NotificationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((errorResponse: HttpErrorResponse) => {
                    this.processError(errorResponse);
                    return throwError(errorResponse);
                })
            );
    }

    processError(errorResponse: HttpErrorResponse): void {
        if (!environment.production) {
            console.log("error", errorResponse);
        }

        switch (errorResponse.status) {
            case 401:
                break; //pass-through to auth handler

            default:
                this.notificationService.showServerError(errorResponse.error ?? errorResponse.message);
                break;
        }
    }
}


import { LookupItem, LookupTypeEnum } from "@/shared/models/lookup";
import { LookupList } from "@/shared/models/lookup/lookup-list.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CacheRegistrationService } from "../services";

@Injectable({ providedIn: 'root' })
export class LookupApiService {
    
    private lookupUriBase = `api/lookup`;
    constructor(
        private http: HttpClient,
        private cacheRegistrationService: CacheRegistrationService
    ) {
        const lookupValues = Object.keys(LookupTypeEnum)
            .map(key => LookupTypeEnum[key])
            .filter(value => typeof value === "number") as number[];

        lookupValues.forEach(lookup =>
            cacheRegistrationService.addToCache(`${this.lookupUriBase}/${lookup}`)
        );
    }

    getLookupList(lookupType: LookupTypeEnum): Observable<LookupItem[]> {
        return this.http.get<LookupItem[]>(`${this.lookupUriBase}/${lookupType}`);
    }

    getAll(): Observable<LookupList[]> {
        return this.http.get<LookupList[]>(`${this.lookupUriBase}`);
    }

}

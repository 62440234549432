import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialogModel } from "../confirm/confirm.model";

@Component({
    selector: 'app-processing',
    templateUrl: './processing.component.html'
})
export class ProcessingDialogComponent {
    title: string;
    message: string;
    constructor(public dialogRef: MatDialogRef<ProcessingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
        this.message = data.message;
        this.title = data.title;
    }
}

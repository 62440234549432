import { Answer } from "./answer.model";
import { ParticipantBasic } from "./participant.model";
import { PositionEnum } from "./position.enum";
import { ParticipantRaterStatusEnum } from "./rater-status.enum";

export class ParticipantRater {
    participantRaterId: number;
    participant: ParticipantBasic;
    answers: Answer[];
    participantRaterStatusId: ParticipantRaterStatusEnum;
    positionId: PositionEnum;
    updateUserId: number;
}

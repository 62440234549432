import { AuthenticationService } from '@/core/authentication/authentication.service';
import { AuthorizationService } from '@/core/authorization/authorization.service';
import { EmailApiService } from '@/core/http/email-api.service';
import { UserApiService } from '@/core/http/user-api.service';
import { NotificationService } from '@/core/services';
import { EmailPayload, EmailTemplateEnum } from '@/shared/models/email';
import { KeyTypeEnum } from '@/shared/models/save-result';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, filter } from 'rxjs/operators';

@Component({
    selector: 'app-forgot-password',
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    loginForm: UntypedFormGroup;
    private userId: number;

    constructor(
        private fb: UntypedFormBuilder,
        private emailService: EmailApiService,
        private notificationService: NotificationService,
        private userService: UserApiService,
        private dialogRef: MatDialogRef<ForgotPasswordComponent>
    ) { }

    ngOnInit() {
        this.initializeForm();
    }

    private initializeForm(): void {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        })

    }

    sendPassword(): void {
        const email = this.loginForm.get("email").value;
        this.userService.validateEmail(email).pipe(
            concatMap(id => {
                if (id) {
                const payload = new EmailPayload();
                payload.keyType = KeyTypeEnum.User;
                payload.keys = [id];
                payload.templateId = EmailTemplateEnum.ForgotPassword;
                return this.emailService.sendForgotPassword(payload);
                } else return of(false);
            }
        ))
        .subscribe(result => {
            if (result) {
            this.notificationService.showSuccess("An email address was found and instructions sent");
            } else {
                this.notificationService.showSuccess("The email was not found.");
            }
            this.dialogRef.close();
        });

    }
}

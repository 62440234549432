import { AuthenticationService } from '@/core/authentication/authentication.service';
import { AppUser } from '@/core/authorization/models';
import { UserApiService } from '@/core/http/user-api.service';
import { DialogService, NotificationService } from '@/core/services';
import { RaterEditComponent } from '@/modules/participant/components/rater/rater.component';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-profile',
    templateUrl: 'profile.component.html',
    styleUrls: ['profile.component.scss']
})
export class ProfileComponent implements OnInit {

    profileForm: UntypedFormGroup;

    private subscription = new Subscription();
    private user: AppUser;

    @ViewChild('firstName', { static: false }) firstNameElement: ElementRef;
    @ViewChild('email', { static: false }) emailElement: ElementRef;

    constructor(
        private userApi: UserApiService,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<RaterEditComponent>,
        private notificationService: NotificationService
    ) { }


    ngOnInit() {
        this.initializeForm();

    }

    initializeForm(): void {
        this.profileForm = this.fb.group({
            userId: [null],
            userTypeId: [null],
            firstName: [null, [Validators.required, Validators.maxLength(50)]],
            lastName: [null, [Validators.required, Validators.maxLength(50)]],
            email: [null, { updateOn: 'blur', validators: [Validators.email, Validators.required] }],
            workPhone: [null]
        }
        );

        this.userApi.getUser(0).subscribe(user => {
            this.user = user;
            this.patchForm();
        }
        );

        this.subscription.add(this.profileForm.get("email").valueChanges.pipe(
            switchMap(email => {
                if (this.profileForm.get("email").valid) {
                    return this.userApi.validateEmail(email);
                } else return of(null);
            }
            )).subscribe(userId => {
                console.log("email check", userId);
                if (userId && userId !== this.user.appUserId) {
                    this.notificationService.showClientError("This email is associated with a different account.");
                    this.profileForm.get("email").setValue(this.user.email);
                }
            })
        );
    }

    onCancel() {
        this.dialogRef.close();
    }

    save(): void {
        const updatedUser = { ...this.user, ...this.profileForm.getRawValue() };
        this.userApi.save(updatedUser).subscribe(_ => {
            this.notificationService.showSuccess("Profile has been updated");
            this.profileForm.markAsUntouched();
            this.profileForm.markAsPristine();
        });

    }

    private patchForm() {
        this.profileForm.patchValue(this.user);
        this.profileForm.markAsUntouched();
        this.profileForm.markAsPristine();

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}

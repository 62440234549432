import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })

export class CacheRegistrationService {
    private services:string[] = [];

    public addedToCache(serviceUri: string): boolean {
        return this.services.indexOf(serviceUri) >= 0;
    }

    public addToCache(serviceUri: string) {
        if (!this.addedToCache(serviceUri)) {
            this.services.push(serviceUri);
        }
    }
}
import { AuthorizationService } from '@/core/authorization/authorization.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { WebLogin } from '../models';

@Component({
    selector: 'app-content-home',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private authService: AuthenticationService,
        private authorization: AuthorizationService,
        private router: Router,
        private dialogRef: MatDialogRef<LoginComponent>
    ) { }

    ngOnInit() {

        this.initializeForm();
    }

    private initializeForm(): void {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        })

    }

    login(): void {
        const webLogin: WebLogin = {...this.loginForm.value};
        this.authService.login(webLogin).subscribe(res => {
            if (res) {
                this.router.navigate([res[1]]);
            }
            this.dialogRef.close(true);
        });

    }
}

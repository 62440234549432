import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  public connection: signalR.HubConnection;

  constructor(){}

  public initiateSignalRConnection(token: string): void {

    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        return token;
      }
    };
  
    const url = (environment.production) ? "/signalr" : "https://localhost:5001/signalr";

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, options)
      .build();

    this.connection.start()
    .then(() => {
      console.log('SignalR Connected!');
    }).catch(function (err) {
      return console.error(err.toString());
    });
  }

  closeConnection(): void {
    if (this.connection?.state == signalR.HubConnectionState.Connected) {
      this.connection.stop();
    }
  }
}
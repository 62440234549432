import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { HomeComponent } from './modules/main/home/home.component';
import { RaterLoginComponent } from './core/authentication/components/rater-login.component';
import { RaterTestComponent } from './modules/admin/components/rater-test/rater-test.component';
import { AdminAuthGuard } from './core/guards/admin.guard';
import { ClientAdminAuthGuard } from './core/guards/client-admin.guard';
import { ParticipantAuthGuard } from './core/guards/admin.guard copy';
import { ForgotPasswordWrapperComponent } from './shared/components/forgot-password/forgot-password-wrapper.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'raterLogin/:raterGuid', component: RaterLoginComponent },
  { path: 'raterTest', component: RaterTestComponent },
  { path: 'forgotPassword', component: ForgotPasswordWrapperComponent },  
  {
    path: 'rater',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, AdminAuthGuard],
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'participant',
    canActivate: [AuthGuard, ParticipantAuthGuard],
    loadChildren: () => import('./modules/participant/participant.module').then(m => m.ParticipantModule)
  },
  {
    path: 'client',
    canActivate: [AuthGuard, ClientAdminAuthGuard],
    loadChildren: () => import('./modules/client-admin/client-admin.module').then(m => m.ClientAdminModule)
  },
  {
    path: 'question',
    canActivate: [AuthGuard, AdminAuthGuard],
    loadChildren: () => import('./modules/admin-question/admin-question.module').then(m => m.AdminQuestionModule)
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

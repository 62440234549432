<mat-toolbar color="primary">
    <h2 mat-dialog-title>Login</h2>
</mat-toolbar>
<mat-dialog-content [formGroup]="loginForm">
    <span>
        <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
        </mat-form-field>
    </span>
    <span>
        <mat-form-field class="full-width">
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" type="password"/>
        </mat-form-field>
    </span>
    <span style="padding-top: 20px;">
        <a routerLink="forgotPassword">I forgot my password</a> (Admins or Active Participants Only)
    </span>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="login()" [disabled]="!loginForm.valid">Login</button>
    <button mat-raised-button [mat-dialog-close]="false" >Cancel</button>
</mat-dialog-actions>
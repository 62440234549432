import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ErrorPayload } from '@/shared/models/system/error-payload.model';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    private serverErrorSubject = new BehaviorSubject<ErrorPayload | null>(null);
    private clientErrorSubject = new BehaviorSubject<Error | null>(null);


    getClientMessage(): Observable<string> {
        let message: string = "";
        if (!navigator.onLine) {
            message = 'No Internet Connection';
        } else {

            const error = this.clientErrorSubject.value;
            if (error) {
                message = error.message ?? error.toString();
            }
        }

        return of(message);
    }


    getServerError(): Observable<ErrorPayload | null> {
        return this.serverErrorSubject.asObservable();
    }

    getServerErrorMessage(): Observable<string> {
        if (this.serverErrorSubject.value) {
            return of(this.serverErrorSubject.value.errorMessages.join('<br/>'));
        } else return of("");
    }

    setServerError(error: ErrorPayload) {
        this.serverErrorSubject.next(error);
    }

    setClientError(error: Error) {
        this.clientErrorSubject.next(error);
    }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from '../authorization/authorization.service';
import { ResourceEnum, RightsEnum } from '../authorization/models';
import { NotificationService } from '../services';

@Injectable({ providedIn: 'root' })
export class ClientAdminAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authorizationService: AuthorizationService,
        private notificationService: NotificationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const hasAccess = (this.authorizationService.hasRightsToResource(ResourceEnum.ClientAdmin, RightsEnum.Create));
        if (!hasAccess) {
            this.notificationService.showClientError("You must be a Client Administrator to access this page.");
            this.router.navigate([''], { queryParams: { returnUrl: state.url }});
        }

        return hasAccess;
    }
}

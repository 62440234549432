import { AuthenticationService } from "@/core/authentication/authentication.service";
import { AuthorizationService } from "@/core/authorization/authorization.service";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ForgotPasswordComponent } from "./forgot-password.component";

@Component({
    selector: 'app-forgot-password-wrapper',
    template: ""

})
export class ForgotPasswordWrapperComponent implements OnInit {

    constructor(
        private dialog: MatDialog,
        public authService: AuthenticationService,
        public authorizationService: AuthorizationService,
        public router: Router
        ) { }
    
      ngOnInit() {
        const dialogRef = this.dialog.open(ForgotPasswordComponent);      
        dialogRef.afterClosed().subscribe(result => {
            console.log("result", result);
            this.router.navigate(["/"]);
        })
      }
    
      login(): void {

      }
    
      ngOnDestroy() {
      }
    
    }
    
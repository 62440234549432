import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ReportDataService } from './modules/admin-shared/services/report.service';
import { LookupDataService } from './shared/services/lookup-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private _initialized=false;
  title = 'Lighthouse 360';

  constructor(
    private lookup: LookupDataService,
    private report: ReportDataService
  ){

  }

  ngOnInit(): void {
    forkJoin({lookups:this.lookup.initialize(), reports: this.report.loadReports()}).subscribe(_ => this._initialized = true);
  }

  get initialized(): boolean {
    return this._initialized;
  }
}

import { Participant } from "@/modules/participant/models";
import { ClientManagementEnum } from "./client-management.enum";
import { SurveyStatusEnum } from "./survey-status.enum";

export class SurveyStats {
    public id: string;
    public surveyId: number;
    public surveyClientId: number;
    public surveyTitle: string;
    public surveyEndDate: Date;
    public surveyStatusId: SurveyStatusEnum;
    public surveyClientName: string;
    public surveyStatus: string;
    public clientManagementId: ClientManagementEnum;
    public participants: Participant[];
}

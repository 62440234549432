import { LookupApiService } from "@/core/http/lookup-api.service";
import { Injectable } from "@angular/core";
import { Observable,map, pipe } from "rxjs";
import { LookupItem, LookupTypeEnum } from "../models/lookup";

@Injectable({
    providedIn: 'root'
})

export class LookupDataService {

    private _lookups: Map<LookupTypeEnum, LookupItem[]> = new Map<LookupTypeEnum, LookupItem[]>();

    constructor(
        private lookupApiService: LookupApiService
    ) {

    }

    initialize(): Observable<{}> {
        return this.lookupApiService.getAll().pipe(map(
            result => {
            result.forEach(ll => this._lookups.set(ll.lookupType, ll.lookupItems));
            return {};
            }));
    }

    lookupList(lookupType: LookupTypeEnum): LookupItem[] {
        return this._lookups.get(lookupType);
    }

    lookupItem(lookupType: LookupTypeEnum, value: number): LookupItem {
        return this.lookupList(lookupType).find(l => l.id === value);
    }

}

import { Pipe, PipeTransform } from '@angular/core';
/*
 * Displays boolean value as a text.
 * Usage
 *   value | bool:displayValueForTrue:displayValueForFalse
 * e.g.
 *   value | bool:'Okay':"Not Okay'
*/
@Pipe({ name: 'bool' })
export class BoolPipe implements PipeTransform {

    transform(
        value: boolean,
        displayValueForTrue = 'Yes',
        displayValueForFalse = 'No',
        displayValueForNullOrUndefined = displayValueForFalse,
    ): string {
        if (value == null) return displayValueForNullOrUndefined;
        return value ? displayValueForTrue : displayValueForFalse;
    }

}
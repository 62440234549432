export class CommonRegexPatterns {
    static PHONE = '^([0-9]{3}) [0-9]{3}-[0-9]{4}$'; //i.e. 206/222-3333
    static ZIPCODE = '^[0-9]{5}(?:-[0-9]{4})?$'; //i.e. 98104-1234 ; 98104
    static SSN = '^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$'
    static DATE = '^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$'
    static UP_TO_ONE_HUNDRED_PERCENT = '^(100|[1-9]?[0-9])$'
    static NUMERIC = '^([0-9]*\.)?[0-9]+$';
    static EIGHT_DIGIT_WITH_TWO_DECIMAL_ALLOWING_ZERO = '^\\d{1,8}(\\.\\d{1,2})?$'
    static EIGHT_DIGIT_WITH_TWO_DECIMAL_NOT_ALLOWING_ZERO = '(?!^0*$)(?!^0*\\.0*$)^\\d{1,8}(\\.\\d{1,2})?$';
    static BANK_TRANSIT_NUMBER = '^(?!5)[0-9]{9}$'; //must be 9 digit numeric and cannot begin with the number 5

    static PASSWORD_SPECIAL_CHARACTERS = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/;
    static PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,100})/;    
}


import { Pipe, PipeTransform } from '@angular/core';
import { PositionEnum } from '../models/survey';

@Pipe({ name: 'position' })
export class PositionPipe implements PipeTransform {

    transform(
        value: PositionEnum
    ): string {
        switch(value) {
            case PositionEnum.DirectReport: return "Direct Report";
            default:return PositionEnum[value];
        }
        
    }

}
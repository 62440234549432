import { Pipe, PipeTransform } from '@angular/core';
import { ParticipantRaterStatusEnum } from '../models/survey';

@Pipe({ name: 'raterStatus' })
export class RaterStatusPipe implements PipeTransform {

    transform(
        value: ParticipantRaterStatusEnum
    ): string {
        return ParticipantRaterStatusEnum[value];
    }

}
import { AnswerStatusEnum } from "./answer-status.enum";

export class AnswerReview {
    questionOrder: number;
    questionText: string;
    answerText: string;
    questionnaireQuestionId: number;
    marked: boolean;
    hasComment: boolean;
    answerStatusId: AnswerStatusEnum;
}
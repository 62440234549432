import { AuthenticationService } from '@/core/authentication/authentication.service';
import { SecurityApiService } from '@/core/http/security-api.services';
import { NotificationService } from '@/core/services';
import { CommonRegexPatterns } from '@/shared/utilities';
import { mustMatch } from '@/shared/validators/must-match.validator';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangePassword } from './change-password.model';

@Component({
    selector: 'app-change-password',
    templateUrl: 'change-password.component.html',
    styleUrls: ['change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    loginForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private dialogRef: MatDialogRef<ChangePasswordComponent>,
        private authService: AuthenticationService,
        private securityApi: SecurityApiService,
        private notificationService: NotificationService
    ) { }

    ngOnInit() {

        this.initializeForm();
    }

    private initializeForm(): void {
        this.loginForm = this.fb.group({
            userId: [this.authService.cloneCurrentUser().appUserId],
            password: [null, Validators.required],
            newPassword: [null, [Validators.required, Validators.pattern(CommonRegexPatterns.PASSWORD)]],
            newPassword2: [null, [Validators.required, Validators.pattern(CommonRegexPatterns.PASSWORD)]],

        }, {
            validators: [mustMatch('newPassword', 'newPassword2')]
        })
    }

    changePassword(): void {
        const payload: ChangePassword = {...this.loginForm.value};
        this.securityApi.changePassword(payload).subscribe(_ => {
            this.notificationService.showSuccess("Password has been changed");
            this.dialogRef.close();
        })
    }
}

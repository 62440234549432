import { Pipe, PipeTransform } from '@angular/core';
import { ParticipantStatusEnum } from '../models/survey';


@Pipe({ name: 'participantStatus' })
export class ParticipantStatusPipe implements PipeTransform {

    transform(
        value: ParticipantStatusEnum
    ): string {
        return ParticipantStatusEnum[value];
    }

}
import { Pipe, PipeTransform } from '@angular/core';
import { AnswerTypeEnum } from '../models/question';

@Pipe({ name: 'answer_type' })
export class AnswerTypePipe implements PipeTransform {

    transform(
        value: AnswerTypeEnum
    ): string {
        switch(value) {
            case AnswerTypeEnum.CheckBox: return "Check Box";
            case AnswerTypeEnum.RadioButton: return "Radio Button";
            case AnswerTypeEnum.Comment: return "Text Comment";
            default: return "";
        }
    }

}
<mat-toolbar color="primary">
    <h2 mat-dialog-title>Forgot Password</h2>
</mat-toolbar>
<mat-dialog-content [formGroup]="loginForm">
    <span>
        <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
        </mat-form-field>
    </span>
    <p>
        Enter your email address above. If the email address exists, <br/>you will receive an email with instructions on logging in.
    </p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="sendPassword()" [disabled]="!loginForm.valid">Ok</button>
    <button mat-raised-button [mat-dialog-close]="false" >Cancel</button>
</mat-dialog-actions>
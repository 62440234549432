<mat-card>
	<mat-card-title>Rater Testing</mat-card-title>
	<mat-card-content>
		<p>Use this page to test the survey. Enter the Survey Key from a Rater Email launch link and click on 'Rater Login.'</p>
		<mat-form-field style="width: 50%">
			<mat-label>Enter a Rater Login Key</mat-label>
			<input placeholder="Enter a Rater Login Key" matInput [formControl]="raterCodeControl">
		</mat-form-field>
	</mat-card-content>
	<mat-card-actions>
		<button mat-raised-button [disabled]="!raterCodeControl.valid" (click)="raterLogin()" color="primary">Rater
			Login</button>
		<div fxFlex></div>
		<button mat-raised-button [disabled]="!raterCodeControl.valid" (click)="resetRater(false)" color="primary">Clear
			Finished Status</button>
		<button mat-raised-button [disabled]="!raterCodeControl.valid" (click)="resetRater(true)" color="primary">Reset
			Rater</button>
	</mat-card-actions>
</mat-card>
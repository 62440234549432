import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export function mustMatch(controlName: string, matchingControlName: string, errorProperty: string = "mustMatch"): ValidatorFn {
    return (formGroup: UntypedFormGroup): {[key: string]: any } | null => {
        const control = formGroup.get(controlName);
        const matchingControl = formGroup.get(matchingControlName);

        if (control.value !== matchingControl.value) {
            const error = {mustMatch: errorProperty};
            return error;
        } else {
            return null;
        }
    }
}
import { SurveyStatusEnum } from "./survey-status.enum";

export class SurveyForList {
    id: string;
    surveyId: number;
    surveyTitle: string;
    surveyClientName: string;
    surveyStartDate: Date;
    surveyEndDate: Date;
    surveyStatus: string;
    participantCount: number;
    surveyClientId: number;
    surveyStatusId: SurveyStatusEnum;
}
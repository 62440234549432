import { ClientManagementEnum } from "./client-management.enum";
import { SurveyStatusEnum } from "./survey-status.enum";

export class Survey {
    id: string;
    surveyId: number;
    surveyTitle: string;
    surveyStartDate: Date;
    surveyEndDate: Date;
    raterEndDate: Date;
    participantEndDate: Date;
    surveyStatusId: SurveyStatusEnum;
    surveyClientId: number;
    minimumRaters: number;
    questionnaireId: number;
    surveyHelpContact: string;
    reminderIntervalDays: number;
    surveyAdminUserId: number;
    showCategories: boolean;
    participantManagement: boolean;
    clientManagementId: ClientManagementEnum;
    updateUserId: number;
}

import { AuthenticationService } from '@/core/authentication/authentication.service';
import { RaterApiService } from '@/core/http/rater-api.service';
import { DialogService, NotificationService } from '@/core/services';
import { RaterForTest } from '@/shared/models/survey';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { concatMap, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-rater-test',
  templateUrl: './rater-test.component.html',
  styleUrls: ['./rater-test.component.scss']
})
export class RaterTestComponent implements OnInit, OnDestroy {

  raterCodeControl = new FormControl("", [
    Validators.required
  ]);

  private _selectedKey: string;

  constructor(
    private dialog: MatDialog,
    private raterApiService: RaterApiService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
  }

  raterLogin(): void {
    const url = `${window.location.origin}/raterLogin/${this.raterCodeControl.value}`;
    window.open(url, "_blank");
  }

  get selectedKey(): string {
    return this._selectedKey;
  }

  set selectedKey(value: string) {
    this._selectedKey = value;
    this.raterCodeControl.setValue(value);
    this.raterCodeControl.markAsDirty();
    this.raterCodeControl.markAsTouched();
  }

  resetRater(fullReset: boolean): void {
    const message = (fullReset) ? "Confirm resetting this rater's active surveys. All answers will be deleted!" :
      "Confirm rolling back this rater's finished status. Answers will not be changed.";
    this.dialogService.openConfirmDialog("Reset Rater", message).pipe(
      concatMap(result => {
        if (result) {
          return this.raterApiService.resetRater(this.raterCodeControl.value, fullReset);

        } else return of(false)
      })
    ).subscribe(result => {
      if (result) {
        this.notificationService.showSuccess("Rater has been reset");
      }

    })

  }

  ngOnDestroy() {
  }

}

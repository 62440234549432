import { KeyTypeEnum, SaveResult } from "@/shared/models/save-result";
import { Rater, RaterFinish } from "@/shared/models/survey";
import { RaterAnswer } from "@/shared/models/survey/rater-answer.model";
import { RaterForTest } from "@/shared/models/survey/rater-test.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class RaterApiService {

    private baseUrl = "api/rater";

    constructor(private http: HttpClient) { }


    getRater(): Observable<Rater> {
        return this.http.get<Rater>(`${this.baseUrl}`);
    }

    save(raterAnswers: RaterAnswer[]): Observable<SaveResult<KeyTypeEnum, number>> {
        return this.http.post<SaveResult<KeyTypeEnum, number>>(this.baseUrl, raterAnswers);
    }

    finish(raterFinish: RaterFinish): Observable<{}> {
        return this.http.post(`${this.baseUrl}/finish`, raterFinish);
    }

    resetRater(surveyKey: string, fullReset: boolean): Observable<boolean> {
        return this.http.delete(`${this.baseUrl}/reset/${surveyKey}/${fullReset}`, {}).pipe(map(_ => true));
    }

    ratersForTesting(surveyClientId: number): Observable<RaterForTest[]> {
        return this.http.get<RaterForTest[]>(`api/test/raterlist/${surveyClientId ?? -1}`);
    }

    raterForTesting(participantRaterId: number): Observable<RaterForTest> {
        return this.http.get<RaterForTest>(`api/test/rater/${participantRaterId}`);
    }

}

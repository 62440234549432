<ng-container *ngIf="{loggedIn: authService.loggedIn$ | async, user: authService.currentUser$ | async} as auth">
	<ng-container *ngIf="auth.loggedIn;else noauth">
		<section>
			<mat-card>
				<mat-card-title>
					<h2>Welcome {{auth.user.appUser.firstName}}!</h2>
				</mat-card-title>
				<mat-card-content>
					<a [routerLink]="authorizationService.userHome$ | async">Click Here to go to your home page.</a>
				</mat-card-content>
			</mat-card>
		</section>
	</ng-container>
</ng-container>
<ng-template #noauth>
	<div class="welcome-screen flex-container">
		<mat-card>
			<mat-card-title>Welcome!</mat-card-title>
				<mat-card-content>
					<p>You must be logged in to use this site. Please click on the 'Login' button below.</p>
					<p>Please visit our <a href="http://lighthouseinstitute.com" target="_blank">main site</a> to learn more about our 360&#176; Assessments</p>
				</mat-card-content>
				<mat-card-actions>
					<button mat-raised-button (click)="login()" color="accent">Login<mat-icon>login</mat-icon></button>
				</mat-card-actions>
		</mat-card>
	</div>
</ng-template>
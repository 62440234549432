import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '@/shared/components/confirm/confirm.component';
import { ConfirmDialogModel } from '@/shared/components/confirm/confirm.model';
import { ProcessingDialogComponent } from '@/shared/components/processing/processing.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private dialog: MatDialog) { }

    openConfirmDialog(title: string, message: string, hideCancelButton: boolean = false, yesCaption: string = "Yes", noCaption: string = "No"): Observable<boolean> {
        const dialogData = new ConfirmDialogModel(title, message, hideCancelButton, yesCaption, noCaption);
        return this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '400px',
            disableClose: true,
            data: dialogData
        })
            .afterClosed();
    }

    openProcessingDialog(title: string, message: string ): MatDialogRef<ProcessingDialogComponent, any> {
        const dialogData = new ConfirmDialogModel(title, message);
        return this.dialog.open(ProcessingDialogComponent, {
            maxWidth: '400px',
            disableClose: true,
            data: dialogData
        });    
    }

}


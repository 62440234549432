import {Injectable} from '@angular/core';
import cloneDeep from 'clone-deep';

@Injectable({providedIn: 'root'})
export class CloneService {

    deepClone<T>(value: T): T {
        return <T>cloneDeep(value);
    }

}
import { AuthenticationService } from '@/core/authentication/authentication.service';
import { LoginComponent } from '@/core/authentication/components/login.component';
import { AuthorizationService } from '@/core/authorization/authorization.service';
import { DialogService, NotificationService } from '@/core/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(
    private dialog: MatDialog,
    public authService: AuthenticationService,
    public authorizationService: AuthorizationService,
    public router: Router
    ) { }

  ngOnInit() {
  }

  login(): void {
    const dialogRef = this.dialog.open(LoginComponent, this.authService.loginDialogConfig);
  }

  ngOnDestroy() {
  }

}

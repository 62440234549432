import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";


const DEFAULT_TTL_SECONDS = 24 * 60 * 60;

@Injectable({ providedIn: 'root' })
export class RequestCacheService {

    private cache = new Map<string, [Date, HttpResponse<any>]>();

    get(key: string): HttpResponse<any> | null {
        const tuple = this.cache.get(key);
        if (!tuple) return null;

        const expires = tuple[0];
        const httpResponse = tuple[1];

        const now = new Date();
        if (expires && expires.getTime() < now.getTime()) {
            this.cache.delete(key);
            return null;
        }

        return httpResponse;
    }

    set(key: string, value: HttpResponse<any>, ttlSeconds = null) {
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + (ttlSeconds ?? DEFAULT_TTL_SECONDS));
        this.cache.set(key, [expires, value]);
    }

    clear(key: string): void {
        this.cache.delete(key);
    }

    clearAllCache() {
        this.cache = new Map<string, [Date, HttpResponse<any>]>();
    }
}
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, concatMap, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { CredentialToken } from '../authentication/models';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private refreshing: boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.currentUser$.pipe(
            take(1),
            concatMap(user => {
                if (user?.token) {
                    request = this.addToken(request, user.token);
                }

                return next.handle(request);
            }),
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(request, next);
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            headers: request.headers
                .set('Authorization', `Bearer ${token}`)
        }
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log("401 handler", {refreshing: this.refreshing });
        if (!this.refreshing) {
            this.refreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                catchError(error => {
                    console.log("refresh error", error); 
                    this.refreshing = false;
                    this.authService.logout();                    
                    return throwError(error);

                }),
                switchMap((token: CredentialToken) => {
                    this.refreshing = false;
                    this.refreshTokenSubject.next(token.access_token);
                    return next.handle(this.addToken(request, token.access_token));
                })
                );

        } else {
            return this.refreshTokenSubject.pipe(
                tap(result => console.log("checking refresh subject", result)),
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }

}

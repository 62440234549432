import { Report } from "@/modules/admin-shared/models/reports";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ReportingApiService {
    private baseUrl = `api/reporting`;
    constructor(
        private http: HttpClient) { }

    getReports(): Observable<Report[]> {
        return this.http.get<Report[]>(`${this.baseUrl}`);
    }
}
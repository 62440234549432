import { ParticipantRater } from "./participant-rater.model";
import { SurveyQuestion } from "./survey-question.model";

export class RaterSurvey {
    surveyId: number;
    surveyTitle: string;
    surveyClient: string;
    raterEndDate: Date;
    showCategories: boolean;
    participantRaters: ParticipantRater[];
    questions: SurveyQuestion[];
}
import { Injectable } from "@angular/core";
import { HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from "@angular/common/http";
import { CacheRegistrationService } from "../services/cache-registration.service";
import { tap, share } from "rxjs/operators";
import { RequestCacheService } from '../services';


@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    private cache: RequestCacheService,
    private cacheRegistrationService: CacheRegistrationService) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler) {
    let key = "";
    let baseKey = "";
    switch (httpRequest.method) {
      case "GET":
        key = httpRequest.urlWithParams;
        baseKey = key;
        break;        
      case "POST":
        baseKey = httpRequest.urlWithParams;
        key = baseKey + "/" + JSON.stringify(httpRequest.body);
        break;
    }

    if (!this.cacheRegistrationService.addedToCache(baseKey)) {
      return next.handle(httpRequest);
    } else {
      const lastResponse = this.cache.get(key);
      if (lastResponse) {
        return of(lastResponse).pipe(share());
      } else {
        return this.sendRequest(httpRequest, key, next);
      }
    }
  }

  sendRequest(request: HttpRequest<any>, key: string, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.set(key, event);
        }
      }));
  }
}
export enum EmailTemplateEnum {
    None = 0,
    SurveyApproval = 5,
    RaterNotice = 6,
    ParticipantNotice = 7,
    SurveyPublish = 8,
    RaterPastDue = 10,
    ParticipantRaterReminder = 11,
    ParticipantReminder = 12,
    ForgotPassword = 13
}
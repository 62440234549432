export enum KeyTypeEnum {
    Generic = 0,
    Survey = 1,
    Participant = 2,
    ParticipantRater = 3,
    User = 4,
    SurveyClient = 5,
    AnswerTemplate = 6,
    AnswerTemplateDetail = 7,
    Questionnaire = 8,
    QuestionnaireQuestion = 9,
    SurveyGuid = 10,
    QuestionCategory = 11
}
import { AuthenticationService } from '@/core/authentication/authentication.service';
import { LoginComponent } from '@/core/authentication/components/login.component';
import { AuthorizationService } from '@/core/authorization/authorization.service';
import { ResourceEnum, RightsEnum } from '@/core/authorization/models';
import { ChangePasswordComponent } from '@/shared/components/change-password/change-password.component';
import { ProfileComponent } from '@/shared/components/profile/profile.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit, OnDestroy {

  private _loggedIn: boolean = false;
  private subscription = new Subscription();

  userHome$: Observable<string>;

  resourceEnum = ResourceEnum;
  rightsEnum = RightsEnum;

  constructor(
    private dialog: MatDialog, 
    public authenticationService: AuthenticationService, 
    private authorizationService: AuthorizationService,
    private router: Router) {}
  
  get loggedIn(): boolean {
    return this._loggedIn;
  }
  ngOnInit() {
    this.subscription.add(this.authenticationService.loggedIn$.subscribe(result => {
      this._loggedIn = result;
      if (result) {
        this.userHome$ = this.authorizationService.userHome$;
      }
    }));
    
  }

  get loggedInCaption(): string {
    return this._loggedIn ? "Logout" : "Login";
  }

  get loggedInIcon(): string {
    return this._loggedIn ? "lock_open" : "lock";
  }

  login(): void {
    if (!this._loggedIn) {
    const dialogRef = this.dialog.open(LoginComponent, this.authenticationService.loginDialogConfig);
    } else {
      this.logout();
    }
  }

  logout(): void {
    this.authenticationService.logout();
  }

  profile(): void {
    this.dialog.open(ProfileComponent);    
  }

  changePassword(): void {
    this.dialog.open(ChangePasswordComponent);    
  }

  get isRater(): boolean {
    return this.authenticationService.isRater();

  }

  get isParticipant(): boolean {
    return this.authenticationService.isParticipant();

  }

  get isClient(): boolean {
    return this.authenticationService.isClient();
  }

  get isClientOnly(): boolean {
    return (this.authenticationService.isClient() && !(this.authenticationService.isAccountAdmin() || this.authenticationService.isAdmin()));
  }


  hasAccess(resource: ResourceEnum, rights: RightsEnum = RightsEnum.Create): boolean {
    return this.authorizationService.hasRightsToResource(resource, rights);
  }

ngOnDestroy() {
  this.subscription.unsubscribe();
}
}
  
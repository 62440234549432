import { ReportCriteriaEnum } from "./report-criteria.enum";

export class ReportCriteriaValue {
    reportCriteria: ReportCriteriaEnum;
    criteriaValue: string;

    constructor(reportCriteria: ReportCriteriaEnum, criteriaValue: string) {
        this.reportCriteria = reportCriteria;
        this.criteriaValue = criteriaValue;
    }
}
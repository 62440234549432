import { ErrorPayload } from '@/shared/models/system/error-payload.model';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class NotificationService {

    constructor(
        public snackBar: MatSnackBar
    ) { }

    showSuccess(message: string) {
        this.snackBar.open(message, '', { duration: 3000 });
    }

    showServerError(errorPayload: ErrorPayload | string) {

        if (typeof errorPayload === "string") {
            this.snackBar.open(`An error occurred on the server: ${errorPayload}`, 'Close', { panelClass: ['snackbar-error'] });
        } else {
            this.snackBar.open(errorPayload.errorMessages.join('<br/>'), 'Close', { panelClass: ['snackbar-error'] });
        }
    }

    showClientError(message: string) {
        this.snackBar.open(message, 'Close', { duration: 4000, panelClass: ['snackbar-error'] });
    }
    showClientErrorConfirm(message: string) {
        this.snackBar.open(message, 'Close', { panelClass: ['snackbar-error'] });
    }

}
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { NotificationService } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private notificationService: NotificationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.loggedIn$.pipe(
            map(loggedIn => {
                if (loggedIn) {
                    return true;
                } else {
                    this.notificationService.showClientError("You must be logged in to access this page.");
                    this.router.navigate([''], { queryParams: { returnUrl: state.url }});
                    return false;                    
                }
            }),
            catchError((error: HttpErrorResponse) => {
                console.log("guard error", error);
                this.router.navigate([''], { queryParams: { returnUrl: state.url }});
                return throwError(error)
            })
        );

    }
}

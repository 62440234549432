import { SurveyStatusEnum } from '@/modules/admin/models';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'surveyStatus' })
export class SurveyStatusPipe implements PipeTransform {

    transform(
        value: SurveyStatusEnum
    ): string {
        return SurveyStatusEnum[value];
    }

}
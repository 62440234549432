import { ChangePassword } from '@/shared/components/change-password/change-password.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CredentialToken, WebLogin } from '../authentication/models';
import { AuthenticatedUser, UserGroup } from '../authorization/models';
import { CacheRegistrationService } from '../services';

@Injectable({ providedIn: 'root' })
export class SecurityApiService {
    
    private baseUrl = "api/security";
    constructor(
        private http: HttpClient,
        cache: CacheRegistrationService ){
            cache.addToCache(`${this.baseUrl}/groups`);
        }

    login(webLogin: WebLogin): Observable<AuthenticatedUser> {
        return this.http.post<any>(`${this.baseUrl}/login`, webLogin).pipe(
            map(res => {
                const user = new AuthenticatedUser();
                user.appUser = res.user;
                user.token = res.token;
                return user;
            })
        )
    }

    raterLogin(guid: string): Observable<AuthenticatedUser> {
        return this.http.get<any>(`${this.baseUrl}/rater/login/${guid}`).pipe(
            map(res => {
                const user = new AuthenticatedUser();
                user.appUser = res.user;
                user.token = res.token;
                return user;
            })
        )
    }

    getUserGroups(): Observable<UserGroup[]> {
        return this.http.get<UserGroup[]>(`${this.baseUrl}/groups`);

    }

    refreshToken(key: string): Observable<CredentialToken> {
        return this.http.get<CredentialToken>(`${this.baseUrl}/refresh/${key}`);        

    }

    changePassword(payload: ChangePassword): Observable<boolean> {
        return this.http.patch(`${this.baseUrl}/password`, payload).pipe(
            map(_ => true)
        );        
    }
    
}
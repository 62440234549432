import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialDesignImporterModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmDialogComponent } from './components/confirm/confirm.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CommonModule } from '@angular/common';
import { BoolPipe } from "./pipes/bool.pipe";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ProcessingDialogComponent } from "./components/processing/processing.component";
import { RaterStatusPipe } from "./pipes/rater-status.pipe";
import { PositionPipe } from "./pipes/position.pipe";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { ParticipantStatusPipe } from "./pipes/participant-status.pipe";
import { AnswerTypePipe } from "./pipes/answer-type.pipe";
import { SurveyStatusPipe } from "./pipes/survey-status.pipe";
import { GroupListPipe } from "./pipes/group-list.pipe";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const SHARED_COMPONENTS = [
    ConfirmDialogComponent,
    LoadingComponent,
    BoolPipe,
    RaterStatusPipe,
    PositionPipe,
    GroupListPipe,
    ParticipantStatusPipe,
    AnswerTypePipe,
    SurveyStatusPipe,
    ProcessingDialogComponent
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule, 
        MaterialDesignImporterModule,
        HttpClientModule,
        FlexLayoutModule,
        NgxMaskModule.forRoot()
    ],
    declarations: [...SHARED_COMPONENTS],
    exports: [
        ReactiveFormsModule, 
        FormsModule,
        MaterialDesignImporterModule,
        HttpClientModule,
        FlexLayoutModule,        
        ...SHARED_COMPONENTS
 
    ]
})
export class SharedModule {

}
<header>
	<div class="main-menu-container" fxLayout="row" fxLayoutAlign="space-between center">
		<div fxFlex>
			<a [routerLink]="userHome$ | async"><img width="250px" src="assets/images/lighthouse-logo.svg"
					id="header-logo"></a>
		</div>
		<div fxFlex fxLayoutAlign="flex-end" class="main-menu-container__textbox">
			<h1>Lighthouse 360&#176; Assessment</h1>
		</div>
	</div>
	<mat-divider></mat-divider>

	<div>
		<mat-toolbar color="primary" *ngIf="loggedIn">
			<mat-toolbar-row fxLayout="row wrap">
				<ng-container *ngIf="authenticationService.currentUser$ | async as user">
					<div fxShow="true" fxHide.lt-md="true">
						<div class="lighthouse-nav-links" fxLayout="row wrap">
							<span *ngIf="isRater">
								<button id="survey-dashboard" mat-button routerLink="/rater">
									<mat-icon>dashboard</mat-icon>&nbsp;Survey Dashboard
								</button>
							</span>
							<span *ngIf="hasAccess(resourceEnum.Admin, rightsEnum.Read)">
								<button mat-button routerLink="/admin">
									<mat-icon>account_tree</mat-icon>Admin Home
								</button>
								<button mat-button [matMenuTriggerFor]="surveyMenu">
									<mat-icon>settings_suggest</mat-icon>Surveys & Clients
								</button>
								<mat-menu #surveyMenu="matMenu">
									<button mat-menu-item routerLink="/admin/survey/-1" *ngIf="hasAccess(resourceEnum.Admin)">
										<mat-icon>add</mat-icon>New Survey
									</button>
									<button mat-menu-item routerLink="/admin/client/-1" *ngIf="hasAccess(resourceEnum.Admin)">
										<mat-icon>person_add</mat-icon>New Client
									</button>
									<mat-divider></mat-divider>
									<button mat-menu-item routerLink="/admin/survey_search">
										<mat-icon>search</mat-icon>Survey Search
									</button>
									<button mat-menu-item routerLink="/admin/client_search">
										<mat-icon>person_search</mat-icon>Client Search
									</button>
								</mat-menu>
								<button mat-button [matMenuTriggerFor]="questionMenu" *ngIf="hasAccess(resourceEnum.Admin)">
									<mat-icon>quiz</mat-icon>Questionnaires
								</button>
								<mat-menu #questionMenu="matMenu">
									<button mat-menu-item [matMenuTriggerFor]="questionnaireMenu">
										<mat-icon>quiz</mat-icon>Questionnaires
									</button>
									<button mat-menu-item routerLink="/question/answer_manage">
										<mat-icon>rate_review</mat-icon>Answer Types
									</button>
									<button mat-menu-item routerLink="/question/category_search">
										<mat-icon>category</mat-icon>Categories
									</button>
								</mat-menu>
								<mat-menu #questionnaireMenu="matMenu">
									<button mat-menu-item routerLink="/question/questionnaire_search">
										<mat-icon>quiz</mat-icon>Search Questionnaires
									</button>
									<button mat-menu-item routerLink="/question/questionnaire/-1">
										<mat-icon>add</mat-icon>New Questionnaire
									</button>
								</mat-menu>
								<button mat-button routerLink="/admin/report">
									<mat-icon>summarize</mat-icon>Reporting
								</button>
								<button mat-button [matMenuTriggerFor]="userMenu" *ngIf="hasAccess(resourceEnum.Admin)">
									<mat-icon>manage_accounts</mat-icon>Users
								</button>
								<mat-menu #userMenu="matMenu">
									<button mat-menu-item routerLink="/admin/admins">
										<mat-icon>admin_panel_settings</mat-icon>System & Account Admins
									</button>
									<button mat-menu-item routerLink="/admin/clientadmins">
										<mat-icon>badge</mat-icon>Client Admins
									</button>
								</mat-menu>
							</span>
							<span *ngIf="isClient">
								<button mat-button routerLink="/client">
									<mat-icon>rule</mat-icon>Client Home
								</button>
								<button mat-button routerLink="/client/report" *ngIf="isClientOnly">
									<mat-icon>summarize</mat-icon>Reporting
								</button>
							</span>
							<span>
								<button *ngIf="isParticipant" mat-button routerLink="/participant">
									<mat-icon>supervised_user_circle</mat-icon>Participant Home
								</button>
							</span>
						</div>
					</div>

					<div fxShow="true" fxHide.gt-sm="true">
						<button mat-icon-button [matMenuTriggerFor]="dropMenu">
							<mat-icon>menu</mat-icon>
						</button>
					</div>
					<span class="spacer"></span>

					<div><span *ngIf="!isRater;else raterUser"><button class="user-name" mat-button
								[matMenuTriggerFor]="userMenu">{{user.appUser.firstName}}
								{{user.appUser.lastName}}&nbsp;
								<mat-icon>expand_more</mat-icon>
							</button>
							<mat-menu #userMenu="matMenu">
								<button mat-menu-item (click)="profile()">
									<mat-icon color="accent">person</mat-icon>Update Profile
								</button>
								<button (click)="changePassword()" mat-menu-item>
									<mat-icon color="accent">password</mat-icon>Change Password
								</button>
							</mat-menu>
						</span>
						<ng-template #raterUser>
							<span class="user-name">{{user.appUser.firstName}} {{user.appUser.lastName}}
							</span>
						</ng-template>
						<span style="padding-left: 15px;"> <button matTooltip="Logout" mat-icon-button
								(click)="logout()">
								<mat-icon>logout</mat-icon>
							</button></span>
					</div>
				</ng-container>

			</mat-toolbar-row>
		</mat-toolbar>

		<!--Dropdown Menu-->
		<mat-menu #dropMenu="matMenu" class="lighthouse-dropdown">
			<button class="lighthouse-dropdown__link" mat-menu-item routerLink="/rater">
				<mat-icon>dashboard</mat-icon>&nbsp;Survey Dashboard
			</button>
			<button *ngIf="hasAccess(2)" class="lighthouse-dropdown__link" mat-menu-item routerLink="/admin">
				<mat-icon>format_color_text</mat-icon>Survey Manager
			</button>
			<button *ngIf="hasAccess(3)" class="lighthouse-dropdown__link" mat-menu-item routerLink="/participant">
				<mat-icon>supervised_user_circle</mat-icon>Participant Manager
			</button>
		</mat-menu>
	</div>
</header>
import { EmailPayload  } from "@/shared/models/email";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class EmailApiService {
    private baseUrl = `api/email`;
    constructor(
        private http: HttpClient) { }

    sendEmailsForSurvey(payload: EmailPayload): Observable<{}> {
        return this.http.post(`${this.baseUrl}/survey`, payload);
    }

    sendEmail(payload: EmailPayload): Observable<{}> {
        return this.http.post(`${this.baseUrl}`, payload);
    }

    sendForgotPassword(payload: EmailPayload): Observable<boolean> {
        return this.http.post(`${this.baseUrl}/forgot`, payload).pipe(map(_ => true));
    }
}
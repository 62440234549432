import { AuthenticationService } from '@/core/authentication/authentication.service';
import { LoginComponent } from '@/core/authentication/components/login.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-rater-login',
  templateUrl: './rater-login.component.html',
  styleUrls: ['./rater-login.component.scss']
})
export class RaterLoginComponent implements OnInit, OnDestroy {

  private sub = new Subscription();
  loginFailed = false;
  private _errorMessage: string;

  constructor(
    private dialog: MatDialog, 
    public authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.sub = this.route.params.pipe(
      switchMap(params => {
        const guid = params['raterGuid']; 
        return this.authService.raterLogin(guid);
      })
    )    
    .subscribe({
      next: (_) => {
      this.router.navigate(["/rater"]);
    },
    error: (e) => {
      this.loginFailed=true;
      console.log("error", e);
    }
  }
    );
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  set errorMessage(value: string) {
    this._errorMessage = value;
  }

  login(): void {

    const dialogRef = this.dialog.open(LoginComponent, this.authService.loginDialogConfig);

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}

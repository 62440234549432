import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialogModel } from "./confirm.model";

@Component({
    selector: 'ess-confirm',
    templateUrl: './confirm.component.html'
})
export class ConfirmDialogComponent {
    title: string;
    message: string;
    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
        this.message = data.message;
        this.title = data.title;
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    get yesCaption(): string {
        return this.data.hideCancelButton ? "Ok": this.data.yesCaption;
    }
}

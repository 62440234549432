import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { CredentialToken, WebLogin } from './models';
import { concatMap, map, tap } from 'rxjs/operators';
import { MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { SecurityApiService } from '../http/security-api.services';
import { AppUser, AuthenticatedUser, UserGroupEnum } from '../authorization/models';
import { AuthorizationService } from '../authorization/authorization.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CloneService } from '@/shared/utilities/clone.service';
import { Router } from '@angular/router';
import { SignalRService } from '../services/signalr.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private loggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loggedIn$: Observable<boolean> = this.loggedInSubject.asObservable();

    private currentUserSubject: BehaviorSubject<AuthenticatedUser> = new BehaviorSubject<AuthenticatedUser>(null);
    public currentUser$: Observable<AuthenticatedUser> = this.currentUserSubject.asObservable();

    constructor(
        private securityApiService: SecurityApiService,
        private cloneService: CloneService,
        private router: Router,
        private signalRService: SignalRService,
        private authorizationService: AuthorizationService) {
    }

    private setUser(user: AuthenticatedUser): string {
        this.currentUserSubject.next(user);
        this.loggedInSubject.next(!!user);
        if (!this.isRater()) {
            if (user) {
                this.signalRService.initiateSignalRConnection(user.token);
            } else {
                this.signalRService.closeConnection();
            }
        }
        const home = this.authorizationService.setupUser(user);
        if (!environment.production) {
            localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return home;
    }

    login(login: WebLogin): Observable<[AuthenticatedUser, string]> {
        return this.initializeUser(this.securityApiService.login(login));
    }

    raterLogin(guid: string): Observable<[AuthenticatedUser, string]> {
        return this.initializeUser(this.securityApiService.raterLogin(guid));
    }

    private initializeUser(login: Observable<AuthenticatedUser>): Observable<[AuthenticatedUser, string]> {
        let user: AuthenticatedUser;
        return login.pipe(
            concatMap(result => {
                user = result;
                return this.authorizationService.initialize().pipe(
                    map(_ => {
                        return user;
                    })
                )
            }),
            map(user => {
                console.log("setting user", user);
                const home = this.setUser(user);
                console.log("returning user-home")
                return [user, home];
            })
        );
    }

    cloneCurrentUser(): AppUser {
        return this.cloneService.deepClone(this.currentUserSubject.value.appUser);
    }

    logout(): void {
        this.setUser(null);
        localStorage.removeItem('currentUser');
        this.router.navigate([""]);
    }

    get loginDialogConfig(): MatDialogConfig {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        return dialogConfig;
    }

    isRater(): boolean {
        return this.currentUserSubject.value?.appUser.userGroups.some(g => g.userGroupId === UserGroupEnum.Rater);
    }

    isParticipant(): boolean {
        return this.currentUserSubject.value?.appUser.userGroups.some(g => g.userGroupId === UserGroupEnum.Participant);
    }

    isClient(): boolean {
        return this.currentUserSubject.value?.appUser.userGroups.some(g => g.userGroupId === UserGroupEnum.ClientAdmin);
    }

    isAdmin(): boolean {
        return this.currentUserSubject.value?.appUser.userGroups.some(g => g.userGroupId === UserGroupEnum.SysAdmin);
    }

    isAccountAdmin(): boolean {
        return this.currentUserSubject.value?.appUser.userGroups.some(g => g.userGroupId === UserGroupEnum.AccountAdmin);
    }


    isClientOrSysAdmin(): boolean {
        return this.isClient() || this.isAdmin();
    }

    isParticipantAndAdmin(): boolean {
        return (this.isAdmin() || this.isClient() || this.isAccountAdmin()) && this.isParticipant()
    }

    public refreshToken(): Observable<CredentialToken> {
        const user = this.currentUserSubject.value;
        const token = user?.token;
        if (token) {
            const jwt = new JwtHelperService();
            const jt = jwt.decodeToken(token);

            return this.securityApiService.refreshToken(jt.jti).pipe(
                tap(result => {
                    console.log("got refresh token", result);
                    user.token = result.access_token;
                    if (!environment.production) {
                        localStorage.setItem('currentUser', JSON.stringify(user));
                    }
                    this.currentUserSubject.next(user);
                })
            );
        } else {
            this.logout();
            return of();
        }
    }

}

import { UserGroup, UserGroupEnum } from '@/core/authorization/models';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'groupList' })
export class GroupListPipe implements PipeTransform {

    transform(
        value: UserGroup[]      
    ): string {
        
        return value.map(ug => UserGroupEnum[ug.userGroupId].replace("Admin"," Admin")).toString();
    }

}
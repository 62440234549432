import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './modules/main/footer/footer.component';
import { HomeComponent } from './modules/main/home/home.component';
import { LoginComponent } from './core/authentication/components/login.component';
import { NavMenuComponent } from './modules/main/nav-menu/nav-menu.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ExceptionInterceptor, CacheInterceptor } from './core/interceptors';
import { SharedModule } from './shared/shared.module';
import { RaterLoginComponent } from './core/authentication/components/rater-login.component';
import { ProfileComponent } from './shared/components/profile/profile.component';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { ForgotPasswordWrapperComponent } from './shared/components/forgot-password/forgot-password-wrapper.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    RaterLoginComponent,
    ChangePasswordComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    ForgotPasswordWrapperComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExceptionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

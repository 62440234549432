<mat-toolbar color="primary">
    <h2 mat-dialog-title>Change Password</h2>
</mat-toolbar>
<mat-dialog-content [formGroup]="loginForm">
    <span>
        <mat-form-field class="full-width">
            <mat-label>Current Password</mat-label>
            <input matInput formControlName="password" type="password"/>
        </mat-form-field>
    </span>
    <span>
        <mat-form-field class="full-width">
            <mat-label>New Password</mat-label>
            <input matInput formControlName="newPassword" type="password"/>
            <mat-error>Password is a required field and must be 6 characters or more, contain upper and
                lower case letters and numbers</mat-error>
        </mat-form-field>
    </span>
    <span>
        <mat-form-field class="full-width">
            <mat-label>Confirm New Password</mat-label>
            <input matInput formControlName="newPassword2" type="password"/>
            <mat-error>Password is a required field and must be 6 characters or more, contain upper and
                lower case letters and numbers</mat-error>
        </mat-form-field>
    </span>
    <div class="mat-error"
    *ngIf="loginForm.errors?.mustMatch && loginForm.get('newPassword2').valid">
    Passwords do not match.
</div>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="changePassword()" [disabled]="!loginForm.valid">Change Password</button>
    <button mat-raised-button [mat-dialog-close]="false" >Cancel</button>
</mat-dialog-actions>

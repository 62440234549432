import { AnswerStatusEnum } from "./answer-status.enum";

export class Answer {
   participantRaterAnswerId: number;
   answerDetailId: number;   
   surveyQuestionId: number;
   questionnaireQuestionId: number;
   answerStatusId: AnswerStatusEnum;
   answerComment: string;
   answerTemplateDetailId: number;
   marked: boolean;
}

import { Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "environments/environment";
import { Report, ReportCriteria, ReportCriteriaEnum, ReportCriteriaValue } from "../models/reports";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ReportingApiService } from "@/core/http/reporting-api.service";


export const SURVEY_STATUS_REPORT = 17;

@Injectable({ providedIn: 'root' })
export class ReportDataService {

    reportForm: FormGroup;
    allReports: Report[];

    private lastCriteria: Map<ReportCriteriaEnum, number> = new Map<ReportCriteriaEnum, number>();    

    constructor(private fb: FormBuilder, private reportApi: ReportingApiService) {
    }

    loadReports(): Observable<boolean> {
        return this.reportApi.getReports().pipe(
            tap(r => this.allReports = r),
            map(r => true)
        );
    }

    initializeForm(): FormGroup {

        this.reportForm = this.fb.group({
            reportId: [null, Validators.required],
            reportCriteria: this.fb.array([])
        });

        this.reportForm.get("reportId").valueChanges.subscribe(id => {
            this.buildCriteria(+id);

        });

        return this.reportForm;

    }

    saveLastCriteria(): void {
        this.reportCriteria.controls.forEach(rc => {
            const criteria: ReportCriteria = rc.get("reportCriteria").value;
            this.lastCriteria.set(criteria.criteriaType, rc.get("reportParameter").value);
        });
    }

    get reportCriteria(): FormArray {
        return <FormArray>this.reportForm.get("reportCriteria");
    }


    private buildCriteria(reportId: number): void {
        const report = this.allReports.find(r => r.reportId === reportId);
        const criteria = this.reportCriteria;
        criteria.clear();
        if (report.reportCriteria) {
            report.reportCriteria.forEach(c => {
                let defaultValue: number = (this.lastCriteria.has(c.criteriaType)) ? this.lastCriteria.get(c.criteriaType) : null;
                criteria.push(this.fb.group({
                    reportParameter: [(c.hide) ? 1 : defaultValue, Validators.required],
                    reportCriteria: [c],
                    hide: [c.hide]
                })
                );
            });
        }
    }

    reportUrlForReport(report: Report, criteriaValues: ReportCriteriaValue[]): string {
        let url = `${environment.reportServer}?ReportPath=${report.reportPath}`;
        url+= criteriaValues.reduce((acc, cur) => {
            const criteria = report.reportCriteria.find(c => c.criteriaType == cur.reportCriteria);
            return acc + "&" + criteria.ssrsParameterName + "=" + cur.criteriaValue;
        }, "");

        return url;
    }
    reportUrl(): string {
        const report: Report = this.allReports.find(r => r.reportId == +this.reportForm.get("reportId").value);
        let url = `${environment.reportServer}?ReportPath=${report.reportPath}`;

        url += this.reportCriteria.controls.reduce((acc, cur) => {
            const criteria = <ReportCriteria>cur.get("reportCriteria").value;
            if (criteria.ssrsParameterName) {
                switch (criteria.criteriaType) {
                    case ReportCriteriaEnum.Participant:
                    case ReportCriteriaEnum.Survey:
                    case ReportCriteriaEnum.SurveyGroup:
                        return acc + "&" + criteria.ssrsParameterName + "=" + cur.get("reportParameter").value;
                    case ReportCriteriaEnum.ParticipantList:
                        return acc + "&" + criteria.ssrsParameterName + "=" + cur.get("reportParameter").value;
                }
            } else return acc;

        }, "");
        return url;
    }
}

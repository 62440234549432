<mat-toolbar color="primary">
	<h2 mat-dialog-title>Update Profile</h2>
</mat-toolbar>
<ng-container *ngIf="profileForm">
	<mat-dialog-content [formGroup]="profileForm" fxLayout="column">
			<mat-form-field fxFlex>
				<mat-label>Email</mat-label>
				<input #email matInput formControlName="email" maxlength="100">
				<mat-error>Email is required</mat-error>
			</mat-form-field>
			<div fxFlex class="mt">
				<strong>Note:</strong> You will not be able to change your email address if that address is assigned to another account.
			</div>
			<mat-form-field fxFlex>
				<mat-label>First Name</mat-label>
				<input #firstName matInput formControlName="firstName" maxlength="50">
				<mat-error>First Name is required and must be less then 50 characters</mat-error>
			</mat-form-field>
			<mat-form-field fxFlex>
				<mat-label>Last Name</mat-label>
				<input matInput formControlName="lastName" maxlength="50">
				<mat-error>Last Name is required and must be less then 50 characters</mat-error>
			</mat-form-field>
			<mat-form-field fxFlex>
				<mat-label>Work Phone</mat-label>
				<input matInput formControlName="workPhone" mask="(000) 000-0000"
					placeholder="Enter phone in the format (000) 000-0000">
			</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-raised-button color="primary" (click)="save()" [disabled]="!profileForm.valid"><mat-icon>save</mat-icon> Save Changes</button>
		<button mat-button (click)="onCancel()">Close</button>
	</mat-dialog-actions>
</ng-container>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppUser, UserGroupEnum } from "../authorization/models";
import { SurveyClientAdminForList } from "@/modules/admin/models/user-manage/survey-client-admin-for-list.model";
import { KeyTypeEnum, SaveResult } from "@/shared/models/save-result";

@Injectable({ providedIn: 'root' })
export class UserApiService {

    private baseUrl = "api/user";

    constructor(private http: HttpClient) { }

    validateEmail(email: string): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/validateemail/${email}`);
    }

    validateAndGetEmail(email: string): Observable<AppUser> {
        return this.http.get<AppUser>(`${this.baseUrl}/validateget/${email}`);
    }

    getUser(userId: number, includeGroups = false): Observable<AppUser> {
        return this.http.get<AppUser>(`${this.baseUrl}/${userId}/${includeGroups}`);        
    }

    getAdmins(): Observable<AppUser[]> {
        return this.http.get<AppUser[]>(`${this.baseUrl}/admins`);        
    }

    getClientAdmins(): Observable<SurveyClientAdminForList[]> {
        return this.http.get<SurveyClientAdminForList[]>(`${this.baseUrl}/clientadmins`);        
    }


    getUsersByGroup(userGroup: UserGroupEnum ): Observable<AppUser[]> {
        return this.http.get<AppUser[]>(`${this.baseUrl}/groups/${+userGroup}`);        
    }

    getUsersByClient(surveyClientId: number, excludeSurveyId: number): Observable<AppUser[]> {
        return this.http.get<AppUser[]>(`${this.baseUrl}/client/${surveyClientId}/${excludeSurveyId}`);        
    }

    save(user: AppUser): Observable<{}> {
        return this.http.put(`${this.baseUrl}`, user);        
    }

    saveAdmin(user: AppUser): Observable<SaveResult<KeyTypeEnum, number>> {
        return this.http.post<SaveResult<KeyTypeEnum, number>>(`${this.baseUrl}/admin`, user);        
    }
}
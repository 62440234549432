	<ng-container *ngIf="loginFailed;else loading">
	<mat-card>	
		<mat-card-title>Rater Login Error</mat-card-title>
			<mat-card-content>
				<p>The rater login was either invalid or the survey has completed.</p>
			</mat-card-content>
		</mat-card>
	</ng-container>
	<ng-template #loading>
		<app-loading caption="Loading Survey..."></app-loading>
	</ng-template>
export enum SurveyStatusEnum {
    None = 0,
    New = 1,
    Active = 2,
    Complete = 3,
    Deleted = 4,
    Closed = 5
}

export enum SurveyStatusMaskEnum {
    None = 0,
    New = 1,
    Active = 2,
    Complete = 4,
    Deleted = 8,
    Closed = 16,
    All = 255
}